import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from './Footer'
import "./layout.css"

const Layout = ({children}) => {
 
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  return (
    <>
      <Header  siteTitle={data.site.siteMetadata?.title || `Title`}/>
      
        <main>{children}</main>
       <Footer />
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
